@font-face {
    font-family: 'openSans';
    src: url('./fonts/opensans-regular-webfont.woff2') format('woff2'), 
    url('./fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('./fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'quicksand';
    src: url('./fonts/quicksand-variablefont_wght-webfont.woff2') format('woff2'),
         url('./fonts/quicksand-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
html {
    overflow-y: scroll;
    overflow-x: hidden;
}